import React from "react"
import Layout from "../components/layout"
import * as cn from "classnames"
import rodoFile from "../../static/RODO_w_Sempre_2021.pdf"

export default class FormForVolunteer extends React.Component {
    state = {
        loading: true
    }

    componentDidMount() {
        this.setState({loading: false})
    }

    render() {
        const s = this.state

        return (
            <Layout>
                <div className="main-image">
                    <img src="../kontakt.jpeg" alt="" />
                </div>
                <div className="content">
                    <div className="form-container">
                        <div className="page-title">
                            <h3>Formularz dla Wolontariusza</h3>
                        </div>
                        <form className="new-form" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                            <input type="hidden" name="oid" value="00D09000001J9cZ"/>
                            <input type="hidden" name="retURL" value="https://sempre.org.pl/success"/>

                            <div className="form-wrapper">
                                <div className="input">
                                    <p>Imię</p>
                                    <input id="first_name" maxLength="40" name="first_name" size="20" type="text" required/>
                                </div>
                                <div className="input">
                                    <p>Nazwisko</p>
                                    <input id="last_name" maxLength="80" name="last_name" size="20" type="text" required/>
                                </div>
                                <div className="input">
                                    <p>Telefon</p>
                                    <input id="phone" maxLength="40" name="phone" size="20" type="text" required/>
                                </div>
                                <div className="input">
                                    <p>Email</p>
                                    <input id="email" maxLength="80" name="email" size="20" type="text" required/>
                                </div>
                                <div className="input">
                                    <p>Data urodzenia</p>
                                    <input id="00N09000006ONqu" maxLength="100" name="00N09000006ONqu" size="20" type="text" required />
                                </div>
                                <div className="input">
                                    <p>Kierunek studiów</p>
                                    <input id="00N09000006ONqz" maxLength="100" name="00N09000006ONqz" size="20" type="text" required />
                                </div>
                                <div className="flex">
                                    <div className="input">
                                        <label className="checkbox">
                                            <input type="checkbox" className="input-text" id="00N09000006ONr2" name="00N09000006ONr2" value="1" />
                                            <span>Świetlica na Kowalskiej</span>
                                        </label>
                                    </div>
                                    <div className="input">
                                        <label className="checkbox">
                                            <input type="checkbox" className="input-text" id="00N09000006ONr2" name="00N09000006ONr2" value="1"/>
                                            <span>Przystanek Sempre</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="input">
                                        <label className="checkbox">
                                            <input type="checkbox" className="input-text" id="00N09000006ONr2" name="00N09000006ONr2" value="1" />
                                            <span>Centrum Informacji i Rozwoju Młodzieży</span>
                                        </label>
                                    </div>
                                    <div className="input">
                                        <label className="checkbox">
                                            <input type="checkbox" className="input-text" id="00N09000006ONr2" name="00N09000006ONr2" value="1"/>
                                            <span>Wymiany/szkolenia w ramach Erasmus+</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input">
                                    <label className="checkbox">
                                        <input type="checkbox" className="input-text" id="00N09000006ONr2" name="00N09000006ONr2" value="1"/>
                                        <span>Europejski Korpus Solidarności</span>
                                    </label>
                                </div>
                                <div className="input">
                                    <p>Dostępność w ciągu tygodnia (h)</p>
                                    <input id="00N09000006PMX0" maxLength="255" name="00N09000006PMX0" size="20" type="text" required />
                                </div>
                                <div className={cn("input", {invalid: this.state.checkboxError})}>
                                    <label className="checkbox">
                                        <input type="checkbox" className="input-text" id="00N09000006ONr2"
                                               name="00N09000006ONr2" value="1" ref={(el) => {this.agree = el}}/>
                                        <span>Zgoda na przetwarzanie danych osobowych. <a href={rodoFile}
                                                                                          target="_blank">Więcej informacji</a> *</span>
                                    </label>
                                </div>
                                <div className="text-center">
                                    <input type="submit" name="submit" className="btn btn-yellow"/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        )
    }
}
